<template>
    <div>
		<OssUpload @change="change" suffix="txt"></OssUpload>
        <!--<Button type="primary" @click="open(false)">Open notice</Button>-->
        <!--<Button @click="open(true)">Open notice(only title)</Button>-->
        <!--<p>With desc</p>-->
        <!--<Button @click="info(false)">Info</Button>-->
        <!--<Button @click="success(false)">Success</Button>-->
        <!--<Button @click="warning(false)">Warning</Button>-->
        <!--<Button @click="error(false)">Error</Button>-->
        <!--<p>Only title</p>-->
        <!--<Button @click="info(true)">Info</Button>-->
        <!--<Button @click="success(true)">Success</Button>-->
        <!--<Button @click="warning(true)">Warning</Button>-->
        <!--<Button @click="error(true)">Error</Button>-->
        <!--<p>自定义时长</p>-->
        <!--<p>自定义时长，为 0 则不自动关闭。也可以在Notice.config()中全局配置，详见API。</p>-->
        <!--<Button type="primary" @click="time">Open notice</Button>-->
        <!--<p>Render 函数  你可以自定义 Render 函数来替代 desc</p>-->
        <!--<Button type="primary" @click="renderFunc">Open notice</Button>-->
    </div>
</template>

<script>
	import OssUpload from "@/components/OssUpload";
	export default {
        name: "TestNotice",
		components: {
			OssUpload
		},
		methods:{
			change(fileList){
				console.log("--> %o", fileList)
			}
		}
        // methods: {
        //     renderFunc () {
        //         this.$Notice.success({
        //             title: 'Notification title',
        //             desc: 'The desc will hide when you set render.',
        //             render: h => {
        //                 return h('span', [
        //                     'This is created by ',
        //                     h('a', 'render'),
        //                     ' function'
        //                 ])
        //             }
        //         });
        //     },
        //     time () {
        //         this.$Notice.open({
        //             title: 'Notification title',
        //             desc: 'This notification does not automatically close, and you need to click the close button to close.',
        //             duration: 0
        //         });
        //     },
        //     open (nodesc) {
        //         this.$Notice.open({
        //             title: 'Notification title',
        //             desc: nodesc ? '' : 'Here is the notification description. Here is the notification description. '
        //         });
        //     },
        //     info (nodesc) {
        //         this.$Notice.info({
        //             title: 'Notification title',
        //             desc: nodesc ? '' : 'Here is the notification description. Here is the notification description. '
        //         });
        //     },
        //     success (nodesc) {
        //         this.$Notice.success({
        //             title: 'Notification title',
        //             desc: nodesc ? '' : 'Here is the notification description. Here is the notification description. '
        //         });
        //     },
        //     warning (nodesc) {
        //         this.$Notice.warning({
        //             title: 'Notification title',
        //             desc: nodesc ? '' : 'Here is the notification description. Here is the notification description. '
        //         });
        //     },
        //     error (nodesc) {
        //         this.$Notice.error({
        //             title: 'Notification title',
        //             desc: nodesc ? '' : 'Here is the notification description. Here is the notification description. '
        //         });
        //     }
        // }
    }
</script>

<style scoped>

</style>
